import React, { useCallback, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Slider from './../../components/Slider/Slider';
import ServicesPresentation from './../../components/ServicesPresentation/ServicesPresentation';
import { useSelector, useDispatch } from 'react-redux';
import * as familiasActions from '../../store/familias/familias.actions';

const Home: React.FunctionComponent<{}> = React.memo(() => {
    const { familias } = useSelector((state: any) => state.familiasStore);
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!familias.length) dispatch(familiasActions.requestFamilias());
    }, [dispatch, familias.length]);

    useEffect(() => fetchData(), [fetchData]);
    
    return (
        <div>
            <Helmet>
                <title>Héctor Blejer | Representaciones Comerciales</title>
                <meta name="description" content="Hector Blejer. Representaciones comerciales" />
                <meta name="keywords" content="Hector Blejer, hector, blejer, fabrica, fabricas, distribuidor, insumos, limpieza, representaciones comerciales, comercial, representacion, productos, negocios" />
            </Helmet>
            <Slider />
            <ServicesPresentation />
        </div>
    )}
);

export default Home;