import React from 'react';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './ProductRow.scss';

const ReactImageZoom = require('react-image-zoom');

export interface IProductRow {
    codart: string,
    desart: string,
    familia?: string,
    subfamilia?: string,
    price: number,
    imgUrl?: string,
    showCart: boolean,
    unidad: string
};

const ProductRow: React.FC<IProductRow> = (articulo: IProductRow) => {
    const { codart, desart, familia, subfamilia, price, imgUrl, showCart, unidad } = articulo;

    const imgProps = {
        width: 100, 
        height: 80, 
        zoomWidth: 500, 
        scale: 5,
        img: imgUrl ? `http://mcsiles.com.ar/usuarios/blejer2/${imgUrl}` : './assets/no-disponible.jpg',
        zoomPosition: 'right',
        offset: {
            horizontal: 10,
            vertical: -100
        }
    };

    return (
        <tr className="ProductRow">
            <td><ReactImageZoom {...imgProps} /></td>
            <td className="Desart"><p><span className="Codart">{codart.toUpperCase()}</span> {desart.toUpperCase()}</p></td>
            <td className="Categorias"><p>{familia || '-'} <br /> {subfamilia || '-'}</p></td>
            <td className="Price">
                <p><Currency value={price} /></p>
            </td>
            <td>{unidad}</td>
            {showCart ? 
                <td className="QtyCell">
                    <QtySelector 
                        desart={desart}
                        codart={codart} 
                        price={price}
                        isCheckout={false}
                    />
                </td> : null
            }
        </tr>
    );
};

export default ProductRow;