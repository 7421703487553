import React, { useEffect } from "react";
import { Route, Switch, HashRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import * as authActions from './store/auth/auth.actions';
import Auth from './pages/Auth/Auth';
import Checkout from "./pages/Checkout/Checkout";
import Contact from './pages/Contact/Contact';
import Footer from "./components/Footer/Footer";
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import PrivateComponent from "./hocs/PrivateComponent/PrivateComponent";
import Products from './pages/Products/Products';
import Logout from './pages/Auth/Logout/Logout';
import Orders from "./pages/Orders/Orders";
import Forgot from "./pages/Forgot/Forgot";
import Reset from "./pages/Forgot/Reset";
import WpBubble from "./components/WpBubble/WpBubble";

const AppRouter: React.FunctionComponent<{}> = () => {
    const distpatch = useDispatch();

    useEffect(() => { distpatch(authActions.tryAutoLogin()); }, [distpatch]);

    return (
        <HashRouter>
            <div className="main-container">
                <div>
                    <Header />
                </div>
                <div className="content-container">
                    <Switch>
                        <Route
                            path="/productos"
                            component={Products} />

                        <Route
                            path="/mis-pedidos"
                            component={PrivateComponent(Orders)}
                            exact={true} />
                        <Route
                            path="/checkout"
                            component={PrivateComponent(Checkout)}
                            exact={true} />
                        <Route
                            path="/logout"
                            component={Logout}
                            exact={true} />
                        <Route
                            path="/"
                            component={Home}
                            exact={true} />
                        <Route
                            path="/contacto" 
                            component={Contact}
                            exact={true} />
                        <Route
                            path="/ingresar" 
                            component={Auth}
                            exact={true} />
                        <Route
                            path="/registrarme"
                            component={Auth}
                            exact={true} />
                        <Route
                            path="/olvide-mi-contrasena"
                            component={Forgot}
                            exact={true} />
                        <Route
                            path="/new-password"
                            component={Reset}
                            exact={true} />
                    </Switch>
                    <WpBubble />
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </HashRouter>
    );
};

export default AppRouter;