import React from 'react';
import ServicePresentationitem, { IServicePresentation } from './../ServicePresentationItem/ServicePresentationItem';

const ServicesPresentation: React.FunctionComponent<{}> = React.memo(() => {
    const services: IServicePresentation[] = [{ 
        title: '+30 años de trayectoria', 
        text: 'Dedicandome a representar comercialmente a distintas empresas, que depositaron en mí su confianza y a las cuales les debo mi crecimiento personal y laboral.',
        icon: 'TrayectoriaIcon'
    }, {
        title: 'Todos los productos en un click',
        text: 'A través de este medio deseo llegar a mis clientes de una manera más ágil y práctica, facilitándoles el acceso a cada fábrica que represento, sus productos y precios actualizados.',
        icon: 'EntregaIcon'
    }, {
        title: 'El trato de siempre',
        text: 'El trato personal con Uds., mis clientes, continuará siendo una prioridad en nuestra relación comercial, ya que es un valor fundamental para poder sostenernos, encontrarnos, intercambiar y crecer.',
        icon: 'StockIcon'
    }];

    return (
        <div className="SectionsPresentation">
            <div className="container">
                <h3>Hector Blejer</h3>

                <div className="row">
                    {services.map((service: IServicePresentation, index: number) => (
                    <div 
                        className="col-md-4"
                        key={index}>
                        <ServicePresentationitem {...service} />
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
});

export default ServicesPresentation;