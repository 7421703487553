
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppRouter from './AppRouter';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import './index.scss';
import { Store, CombinedState } from 'redux';
import ReduxToastr from 'react-redux-toastr'

import * as firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyBgTwnRY5iojhJpIxJdjoAmuJ-Vh7dBAjA",
    authDomain: "lemmys-56fec.firebaseapp.com",
    databaseURL: "https://lemmys-56fec.firebaseio.com",
    projectId: "lemmys-56fec",
    storageBucket: "lemmys-56fec.appspot.com",
    messagingSenderId: "496122829491",
    appId: "1:496122829491:web:d7b3a3816748f824f56ec6",
    measurementId: "G-XNC7L448SN"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

const store: Store<CombinedState<any>> = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <div>
            <AppRouter />
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
