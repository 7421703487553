import React from 'react';
import './Footer.scss';

const Footer: React.FunctionComponent<{}> = React.memo(() => {
    return (
        <footer className="FooterContainer">
            <div className="MainFooterContainer">
                <div className="container">
                    <div className="row LogosContainer">
                        <div className="col-md-6">
                            <p>
                                <span className="SmallIcon EmailIcon"></span> 
                                <a href="mailto:hectorblejer@gmail.com">hectorblejer@gmail.com</a>
                            </p>
                            <p>
                                <span className="SmallIcon PhoneIcon"></span> 0351-156532783
                            </p>
                            <p>
                                <span className="SmallIcon WpIcon"></span> 351 6532783
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-6 FooterLogoContainer">
                            <span className="SmallIcon FooterLogo"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SubfooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">Hector Blejer | 2020</div>
                        <div className="col-md-6 col-sm-6 text-right">
                            <a 
                                href="http://huskysoftware.com.ar" 
                                target="_blank"
                                rel="noopener noreferrer">Desarrollo <span className="husky-logo"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
