import React, { useState } from 'react';
import Input from '../../core/Input/Input';
import Button from '../../core/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart, removeFromCart } from './../../store/cart/cart.actions';
import { Pedclid } from '../../classes/pedido';
import { toastr } from 'react-redux-toastr';
import './QtySelector.scss';

interface IQtySelector {
    codart: string,
    price: number,
    isCheckout?: boolean,
    desart?: string
};

const QtySelector: React.FC<IQtySelector> = ({ desart = '', codart = '', price = 0, isCheckout = false }) => {
    const cart: Pedclid[] = useSelector((state: any) => state.cartStore.cart);
    const articuloInCart: Pedclid|undefined = cart.find((pd: Pedclid) => pd.CODART === codart);
    const [ productQty, setProductQty ] = useState<number>(articuloInCart?.CANT_PED || 1);
    const dispatch = useDispatch();

    const addToCart = ({ qty = 0 } = {}) => {
        const qtyToUse: number = isCheckout ? qty : productQty;
        const pedclid: Pedclid = new Pedclid(-1, codart, qtyToUse, price, desart, price);
        dispatch(updateCart(pedclid));

        if (!isCheckout) {
            toastr.success(`${desart} agregado al carrito`, `Se han agregado ${productQty} unidades de ${desart} a su carrito.`);
        }
    };

    const onQtyChange = (evt: any = {}): void => {
        const value = evt.target.value;

        let qty = value.toString().replace(',', '.');
        
        if (qty !== '') {
            qty = parseFloat(qty);
        }

        setProductQty(qty);

        if (isCheckout) {
            addToCart({ qty });
        }
    }


    const remove = () => {
        dispatch(removeFromCart(codart));
        setProductQty(1);
        toastr.success(`${desart} se ha quitado del carrito`, `El producto se ha eliminado del carrito.`);
    }

    const renderButtons = () => {
        if (!isCheckout) {            
            return (
                <>
                    <Button 
                        click={addToCart}
                        className="AddBtn">{articuloInCart ? 'MODIFICAR' : 'AGREGAR'}
                    </Button>
                    {articuloInCart ? <Button click={remove} className="RemoveBtn">QUITAR</Button> : null}
                </>
            );
        }
    }
    
    return (
        <div className="QtySelector">
            <Input 
                id={codart}
                className="QtyInput"
                type="number" 
                value={productQty} 
                minLength={1}
                change={onQtyChange}
            />

            {renderButtons()}
        </div>
    );
};

export default QtySelector;